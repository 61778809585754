import React, { useEffect, useRef, useState } from 'react';
import 'aframe';
// import "mind-ar/dist/mindar-image-aframe.prod.js";
import { Button } from '@mui/material';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import Emojis from './Emojis';

const VideoInAR = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  // const [videoObj, setVideoObj] = useState("");
  const sceneRef = useRef(null);
  const videoRef = useRef(null);

  // async function getLikes() {
  // const response = await fetch("http://localhost:8080/annual_meeting_contents/v1/arvideolikes");
  // const data = await response.json();
  // setVideoObj(data[0]);
  // try {
  //   console.log("coming");
  //   axios.get("http://localhost:8080/annual_meeting_contents/v1/arvideolikes").then((val) => {
  //     console.log(val);
  //   });
  //   // const call = axios.get("http://localhost:8080/annual_meeting_contents/v1/arvideolikes");
  //   // .then((res, err) => {
  //   //   if (err) console.log("likes get Error ", err);
  //   //   console.log("Likes Data ", res.data);
  //   //   // setVideoObj(res.data[0]);
  //   // });
  // } catch (error) {
  //   console.log("likes get Error ", error);
  // }
  // }

  useEffect(() => {
    // getLikes();
    const sceneEl = sceneRef.current;
    const videoEle = document.getElementById('sample-video');

    sceneEl.addEventListener('renderstart', () => {
      const arSystem = sceneEl.systems['mindar-image-system'];
      arSystem.start(); // start AR
    });

    sceneEl.addEventListener('targetFound', () => {
      videoEle.play();
    });

    sceneEl.addEventListener('targetLost', () => {
      videoEle.pause();
    });

    sceneEl.addEventListener(
      'targetFound',
      () => {
        setShowBtn(true);
      },
      { once: true }
    );

    sceneEl.addEventListener('targetLost', () => {
      setShowBtn(false);
    });

    return () => {
      const arSystem = sceneEl.systems['mindar-image-system'];
      if (arSystem) {
        arSystem.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (isPlaying) {
      setShowBtn(false);
    }
  }, [isPlaying]);

  const playVideo = () => {
    document.getElementById('sample-video').play();
    setIsPlaying(true);
  };

  return (
    <>
      <div
        style={{
          margin: '100px auto 40px auto',
          position: 'relative',
          height: '450px',
          width: '98%',
          overflow: 'hidden',
        }}
      >
        {showBtn && (
          <Button
            variant='contained'
            startIcon={<ViewInArIcon />}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '30%',
              textTransform: 'none',
              zIndex: 10,
              backgroundColor: '#cc0000',
            }}
            onClick={playVideo}
          >
            Play in AR
          </Button>
        )}

        <a-scene
          ref={sceneRef}
          mindar-image='imageTargetSrc: ./targets.mind; autoStart: false; uiLoading: no; uiError: no; uiScanning: no;'
          color-space='sRGB'
          embedded
          renderer='colorManagement: true, physicallyCorrectLights'
          vr-mode-ui='enabled: false'
          device-orientation-permission-ui='enabled: false'
        >
          <a-assets>
            <video
              ref={videoRef}
              id='sample-video'
              // autoPlay={isPlaying}
              width='16'
              height='9'
              loop='true'
              src={
                'https://gfc.target.com/multiplai/2024/gallery_videos/TargetFam_Together_SIZZLE_REEL_Without_Brian_Final_Approved_With_Melissa.mp4'
              }
              webkit-playsinline
              playsInline
              crossOrigin='anonymous'
            ></video>
            <img id='card' src='./thumb.png' alt='' />
            <a-asset-item
              id='avatarModel'
              src='https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.0/examples/image-tracking/assets/card-example/softmind/scene.gltf'
            ></a-asset-item>
          </a-assets>

          <a-camera position='0 0 0' look-controls='enabled: false'></a-camera>

          <a-entity mindar-image-target='targetIndex: 0'>
            <a-plane src='#card' position='0 0 0' height='0.552' width='1' rotation='0 0 0'></a-plane>
            <a-video src='#sample-video' position='0 0 0.1' width='1' height='0.56'></a-video>
          </a-entity>
        </a-scene>
      </div>
      <Emojis />
    </>
  );
};

export default VideoInAR;
